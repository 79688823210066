import React, { useState } from "react";
import PropTypes from "prop-types";

const MaterialInput = (props) => {
    const { className, id, name, type, label, onChange, value } = props;

    const [text, setText] = useState(value);

    const handleChangeInput = ({ target: { name, value } }) => {
        setText(value);
        if (onChange && typeof onChange === "function") {
            onChange({ name, value });
        }
    };

    return (
        <div className={`MaterialInput ${className}`}>
            <label className="MaterialInput-label">{label}</label>
            <input
                id={id}
                className="MaterialInput-input"
                value={text}
                // placeholder={label}
                onChange={handleChangeInput}
                name={name}
                type={type}
            />
        </div>
    );
};

MaterialInput.defaultProps = {
    className: "",
    type: "text",
    value: "",
};

MaterialInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default MaterialInput;
