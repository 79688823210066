import React from 'react'
import Layout from "../components/Layout";
import Input from "../components/Input";
import Checkbox from "../components/Checkbox";
import Select from "../components/Select";

export default function Register() {
    const options = [
        { value: 'Nicaragua', label: 'Nicaragua' },
        { value: 'Costa Rica', label: 'Costa Rica' },
        { value: 'El Salvador', label: 'El Salvador' }
    ]
    return (
        <Layout>
            <div className="Register">
                <span className="Register-title">Crear cuenta</span>
                <div className="Register-card">
                    <form action="">
                        <Input label="Nombre" />
                        <Input label="Apellido" />
                        <Input label="Email" type="email" />
                        <Input label="Nif" />
                        <Input label="Teléfono" />
                        <Select options={options} />
                        <Input label="Dirección principal" />
                        <div className="AddAddress-row">
                            <Input label="Localidad" className="AddAddress-input" />
                            <Input label="Código Postal" />
                        </div>
                        <Input label="Contraseña" type="password" />
                        <Input label="Confirmar contraseña" type="password" />
                        <Checkbox className="Register-checkbox" classNameLabel="Register-heckLabel" text="Estoy de acuerdo con la política de privacidad" />
                        <button className="Register-btn">Guardar</button>
                    </form>
                </div>
            </div>
        </Layout>
    )
}
