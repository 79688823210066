import React, { useState } from "react"
import PropTypes from "prop-types"

export default function Checkbox(props) {
  const {className, name, checked, value, id, onChange: onChangeProps, text, classNameLabel } = props
  const [isChecked, setChecked] = useState(checked)

  const onChange = (e) => {
    const { name, value, checked } = e.target
    if (onChangeProps && typeof onChangeProps === "function") {
      onChangeProps({name, value, checked})
    }

    setChecked(checked)
  }

  return (
    <label className={`Checkbox-label ${classNameLabel}`} htmlFor={id}>
      <input
        className={`Checkbox ${className}`}
        name={name}
        checked={isChecked}
        value={value}
        type="checkbox"
        onChange={onChange}
        id={id}
      />
      {text}
    </label>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChangeProps: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  text: PropTypes.string,
  id: PropTypes.string,
}

Checkbox.defaultProps = {
  checked: false,
  onChangeProps: () => {},
  name: "",
  value: "",
  text: "",
  id: "",
}
