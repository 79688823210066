import React from 'react'
import Select from 'react-select'

export default function SelectCustom(props) {
    const { options, label, className } = props
    return (
        <div className={`Select ${className}`}>
            <label className="MaterialInput-label">{label}</label>
            <Select options={options}  />
        </div>
    )
}
